import React from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy<React.FC<RouteComponentProps>>(
  () => import('./layout/DefaultLayout'),
);

// Pages
const Login = React.lazy<React.FC<RouteComponentProps>>(() => import('./views/pages/login/Login'));
const Register = React.lazy<React.FC<RouteComponentProps>>(
  () => import('./views/pages/register/Register'),
);
const Page404 = React.lazy<React.FC<RouteComponentProps>>(
  () => import('./views/pages/page404/Page404'),
);
const Page500 = React.lazy<React.FC<RouteComponentProps>>(
  () => import('./views/pages/page500/Page500'),
);

// Create a client
const queryClient = new QueryClient();

export default function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" render={(props) => <Login {...props} />} />
            <Route exact path="/register" render={(props) => <Register {...props} />} />
            <Route exact path="/404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" render={(props) => <Page500 {...props} />} />
            <Route path="/" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
